export enum CanvasModeCode {
    PointCloud = "PointCloud",
    Box = "Box",
    ContourLine = "ContourLine"
}

export enum RenderMode{
    POINT_CLOUD = "PointCloud",
    BOX  = "Box",
    CONTOUR_LINE = "ContourLine",
    SETTINGS = "Settings"
}

export enum CameraMode{
    TOP = "Top",
    LEFT  = "Left",
    RIGHT = "Right",
    FRONT = "Front",
    BACK = "Back",
    THREE_DIMENSION = "ThreeDimension"
}

export const stringToRenderMode = (str: string): RenderMode => {
    switch(str){
        case "PointCloud":
            return RenderMode.POINT_CLOUD;
        case "Box":
            return RenderMode.BOX;
        case "ContourLine":
            return RenderMode.CONTOUR_LINE;       
        case "Settings":
            return RenderMode.SETTINGS;
        default:
            return RenderMode.POINT_CLOUD;
    }
}