import React, { useState } from 'react';
import { FaPlay, FaStop } from 'react-icons/fa'; 

// 소켓 작업
// import React, { useEffect } from 'react';
// import useSocketStore from '../../atoms/socketStore';
// import useSettingStore from '../../atoms/settingsStore';
// import { SocketCommand } from '../../command/SocketCommand';
interface HeaderProps {
  handleModeTabOnClick: (mode: string) => void;
  activeTab: string;
}

const Header: React.FC<HeaderProps> = ({ handleModeTabOnClick, activeTab }) => {
  const [isPlay, setIsPlay] = useState(false); 

  const handleOnButtonClick = () => {
    setIsPlay(!isPlay);
  }
  // 소켓 작업
  // useEffect(() => {
  // }, [])
  // const [send, addListener] = useSocketStore(state => [state.send, state.addListener]);
  // const dataSetting = useSettingStore(state => state.dataSetting);
  // useEffect(() => {
  //   init();
  // }, []);
  // const init = () => {
  //   addListener(SocketCommand.LIDAR_PING, (response) => console.log(response));
  // }
  // const handleStartRecordButtonClick = () => {
  //  send(SocketCommand.START_RECORD, null, (response) => console.log(response));
  // }
  // const handleStopRecordButtonClick = () => {
  //  send(SocketCommand.STOP_RECORD, fileName, (response) => console.log(response));
  // }

  return (
    <div className="header">
      <div
        className={`tab-item ${activeTab === 'Live' ? 'active' : ''}`}
        onClick={() => handleModeTabOnClick('Live')}
      >
        Live Mode
      </div>
      <div
        className={`tab-item ${activeTab === 'Play' ? 'active' : ''}`}
        onClick={() => handleModeTabOnClick('Play')}
      >
        Play Mode
      </div>
      <div className="vertical-line"></div> 
      <div className="save-section">
        <button className="save-button" onClick={handleOnButtonClick}>
          {isPlay ? <FaStop /> : <FaPlay />}
        </button>
        <div>Record</div>
        <div className="input-fields">
          <div className="input-row">
            <label>File name</label>
            <input type="text" value="20241124_000000_153224.DBA" disabled />
          </div>
          <div className="input-row">
            <label>Elapsed Time</label>
            <input type="text" value="00:00:00 - 00:00:00" disabled />
          </div>
        </div>
      </div>
      <div className="vertical-line"></div>
      <div className="status-section">
        <div className="status-row">
          <div className="status-dot"></div>
          <span>Recording : Real Time</span>
        </div>
        <div className="status-row">
          <div className="status-dot"></div>
          <span>Lidar Sensor : 1200ms</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
