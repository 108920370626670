import React from 'react'
// 소켓 작업
// import useSocketStore from '../../atoms/socketStore';
// import useSettingStore from '../../atoms/settingsStore';
// import { gridSettingDataToGridSettingReqData } from '../../converter/SocketConverter';
// import { SocketCommand } from '../../command/SocketCommand';

const GridSettings = () => {
  // 소켓 작업
  // const send = useSocketStore(state => state.send);
  // const gridSetting = useSettingStore(state => state.gridSetting);
  // const handleSaveButtonClick = () => {
  //   const gridSettingReqData = gridSettingDataToGridSettingReqData(gridSetting);
  //   send(SocketCommand.GRID_SETTING, gridSettingReqData, (response) => console.log(response));
  // }
  return (
    <div>GridSettings</div>
  )
}

export default GridSettings