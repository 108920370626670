import React from 'react';
// import useSettingStore from '../../atoms/settingsStore';
// import useSocketStore from '../../atoms/socketStore';
// import { liDARSettingDataToLiDARSettingReqData } from '../../converter/SocketConverter';
// import { SocketCommand } from '../../command/SocketCommand';
const LiDARSettings: React.FC = () => {
  const lidarData = [
    {
      title: 'LiDAR 1',
      data: [
        { label: 'X좌표(m)', value: 'X_LiDAR1', inputValue: 0 },
        { label: 'Y좌표(m)', value: 'Y_LiDAR1', inputValue: 0 },
        { label: 'Z좌표(m)', value: 'Z_LiDAR1', inputValue: 0 },
        { label: 'Roll', value: 'Roll_LiDAR1', inputValue: 30 },
        { label: 'Pitch', value: 'Pitch_LiDAR1', inputValue: '' },
        { label: 'Yaw', value: 'Yaw_LiDAR1', inputValue: '' },
      ],
    },
    {
      title: 'LiDAR 2',
      data: [
        { label: 'X좌표(m)', value: 'X_LiDAR2', inputValue: 0 },
        { label: 'Y좌표(m)', value: 'Y_LiDAR2', inputValue: 0 },
        { label: 'Z좌표(m)', value: 'Z_LiDAR2', inputValue: 0 },
        { label: 'Roll', value: 'Roll_LiDAR2', inputValue: 30 },
        { label: 'Pitch', value: 'Pitch_LiDAR2', inputValue: '' },
        { label: 'Yaw', value: 'Yaw_LiDAR2', inputValue: '' },
      ],
    },
  ];
  return (
    <div className="settings">
      <table className="table">
        <tbody>
          <tr>
            <td>LiDAR 갯수</td>
            <td>No LiDAR</td>
            <td>
              <input type="number" defaultValue={100} />
            </td>
          </tr>
          
          {lidarData.map((lidar, index) => (
            <React.Fragment key={index}>
              <tr>
                <td colSpan={3} className="table-header">
                  {lidar.title}
                </td>
              </tr>
              {lidar.data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{row.label}</td>
                  <td>{row.value}</td>
                  <td>
                    <input type="number" defaultValue={row.inputValue} />
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}

          <tr>
            <td colSpan={3} className="table-header" >Resolution</td>
          </tr>
          <tr>
            <td>Length of Unit</td>
            <td>L Unit</td>
            <td>
              <input type="number" defaultValue={0.5} />
            </td>
          </tr>

          <tr>
            <td colSpan={3} className="table-header" >Renuwal Time</td>
          </tr>
          <tr>
            <td>갱신시간(sec)</td>
            <td></td>
            <td>
              <input type="number" defaultValue={5} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  // 소켓 작업
  // const lidarSetting = useSettingStore((state) => state.liDARSetting);
  // const send = useSocketStore(state => state.send);
  // const handleSaveButtonClick = () => {
  //   const lidarSettingReqData = liDARSettingDataToLiDARSettingReqData(lidarData);
  //   send(SocketCommand.LIDAR_SETTING, lidarSettingReqData, (response) => console.log(response));
  // }

  // return (
  //   <div className="settings">
  //     <table className="table">
  //       <tbody>
  //         <tr>
  //           <td>LiDAR 갯수</td>
  //           <td>No LiDAR</td>
  //           <td>
  //             <input type="number" defaultValue={lidarSetting.no_LiDAR} />
  //           </td>
  //         </tr>
          
  //         {lidarSetting.LiDAR.map((lidar, index) => (
  //           <React.Fragment key={index}>
  //             <tr>
  //               <td colSpan={3} className="table-header">
  //                 LiDAR {index + 1}
  //               </td>
  //             </tr>
  //             <tr>
  //               <td>X좌표(m)</td>
  //               <td>X_LiDAR{index + 1}</td>
  //               <td>
  //                 <input type="number" defaultValue={lidar.X_LiDAR} />
  //               </td>
  //             </tr>
  //             <tr>
  //               <td>X좌표(m)</td>
  //               <td>X_LiDAR{index + 1}</td>
  //               <td>
  //                 <input type="number" defaultValue={lidar.Y_LiDAR} />
  //               </td>
  //             </tr>
  //             <tr>
  //               <td>X좌표(m)</td>
  //               <td>X_LiDAR{index + 1}</td>
  //               <td>
  //                 <input type="number" defaultValue={lidar.Z_LiDAR} />
  //               </td>
  //             </tr>
  //             <tr>
  //               <td>X좌표(m)</td>
  //               <td>X_LiDAR{index + 1}</td>
  //               <td>
  //                 <input type="number" defaultValue={lidar.roll} />
  //               </td>
  //             </tr>
  //             <tr>
  //               <td>X좌표(m)</td>
  //               <td>X_LiDAR{index + 1}</td>
  //               <td>
  //                 <input type="number" defaultValue={lidar.pitch} />
  //               </td>
  //             </tr>
  //             <tr>
  //               <td>X좌표(m)</td>
  //               <td>X_LiDAR{index + 1}</td>
  //               <td>
  //                 <input type="number" defaultValue={lidar.yaw} />
  //               </td>
  //             </tr>
  //           </React.Fragment>
  //         ))}

  //         <tr>
  //           <td colSpan={3} className="table-header" >Resolution</td>
  //         </tr>
  //         <tr>
  //           <td>Length of Unit</td>
  //           <td>L Unit</td>
  //           <td>
  //             <input type="number" defaultValue={lidarSetting.resolution} />
  //           </td>
  //         </tr>

  //         <tr>
  //           <td colSpan={3} className="table-header" >Renuwal Time</td>
  //         </tr>
  //         <tr>
  //           <td>갱신시간(sec)</td>
  //           <td></td>
  //           <td>
  //             <input type="number" defaultValue={lidarSetting.renewalTime} />
  //           </td>
  //         </tr>
  //       </tbody>
  //     </table>
  //   </div>
  // );
};

export default LiDARSettings;
