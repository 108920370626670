import React from 'react';
import './Bar.css'; // 외부 CSS 파일 import

const Bar: React.FC = () => {
    // 하드코딩된 스타일 값
    const height = 200; // 막대의 높이
    const width = 50;   // 막대의 너비
    const colors = [
        0x8B00FF, // Violet
        0x4B0082, // Indigo
        0x0000FF, // Blue
        0x00FFFF, // Cyan
        0x00FF00, // Green
        0xADFF2F, // Lime (Yellow-Green)
        0xFFFF00, // Yellow
        0xFF7F00, // Orange
        0xFF4500, // Red-Orange
        0xFF0000  // Red
    ];
    const gradientColors = colors.map(color => `#${color.toString(16).padStart(6, '0')}`);

    return (
        <div className="container">
            <div
                className="bar"
                style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    background: `linear-gradient(to top, ${gradientColors.join(', ')})`,
                }}
            >
            </div>
            <div className="grid">
                <div>50</div>
                <div>25</div>
                <div>0</div>
            </div>
        </div>
    );
}

export default Bar;