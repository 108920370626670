import { BiSkipPrevious } from "react-icons/bi"; 
import { BiSkipNext } from "react-icons/bi"; 
import React, { useState } from 'react';
import { FaPlay, FaPause, FaForward, FaBackward } from 'react-icons/fa';
// import React, { useState, useEffect } from 'react';

// 소켓 작업
// import useSocketStore from '../atoms/socketStore';

const PlaySidebar: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);
  // 소켓 작업
  // const [fileList, setFileList] = useState([]);
  // const [fileInfo, setFileInfo] = useState({});
  // const [send, addListener] = useSocketStore(state => [state.send, state.addListener]);
  // useEffect(() => {
  //    init();
  // }, []);

  // const init = () => {
  //    addListener(SocketCommand.FILE_LIST, (response) => {
  //      setFileList(response.fileList);
  //    });
  //    addListener(SocketCommand.FILE_INFO, (response) => {
  //      setFileInfo(response.fileInfo);
  //    });
  //   send(SocketCommand.REQ_FILE_LIST, null, (response) => console.log(response));
  // };
  // const handleFileNameClick = () => {
  //   setIsPlaying(true);
  //   send(SocketCommand.REQ_FILE_INFO, fileName, (response) => console.log(response));
  // };
  // const handlePlay = () => {
  //   setIsPlaying(true);
  //   send(SocketCommand.PLAY_FILE, fileInfo.fileName, (response) => console.log(response));
  // };
  // const handlePause = () => {
  //   setIsPlaying(false);
  //   send(SocketCommand.PAUSE_FILE, null, (response) => console.log(response));
  // };
  // const handleStop = () => {
  //   setIsPlaying(false);
  //   setProgress(0);
  //   send(SocketCommand.STOP_FILE, null, (response) => console.log(response));
  // };
  // const handleChangePlaySpeed = (playSpeed) => {
  //   send(SocketCommand.CHANGE_PLAY_SPEED, playSpeed, (response) => console.log(response));
  // };

  const handleOnPlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleOnFastForward = () => {
    setProgress(prev => Math.min(prev + 10, 100));
  };

  const handleOnRewind = () => {
    setProgress(prev => Math.max(prev - 10, 0));
  };

  const handleOnSkipPrevious = () => {
    // 이전 곡으로 넘기기 로직 추가
    console.log('Previous track');
  };

  const handleOnSkipNext = () => {
    // 다음 곡으로 넘기기 로직 추가
    console.log('Next track');
  };

  const handleOnProgressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProgress(Number(e.target.value));
  };

  // 동적 스타일을 위한 스타일 객체 생성
  const progressStyle = {
    background: `linear-gradient(to right, #4caf50 ${progress}%, #444 ${progress}%)`,
  };

  return (
    <div className='sidebar'>
      <div className='play-sidebar'>
        <div className='play-sidebar__controls'>
          <button className='play-sidebar__button' onClick={handleOnRewind}>
            <FaBackward />
          </button>
          <button className='play-sidebar__button' onClick={handleOnSkipPrevious}>
            <BiSkipPrevious size={40} />
          </button>
          <button className='play-sidebar__button' onClick={handleOnPlayPause}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
          <button className='play-sidebar__button' onClick={handleOnSkipNext}>
            <BiSkipNext size={40} />
          </button>
          <button className='play-sidebar__button' onClick={handleOnFastForward}>
            <FaForward />
          </button>
        </div>
        <div className='time-box'>
          <p className='play-sidebar__time'>00:00:00</p>
          <p className='play-sidebar__time'>00:00:00</p>
        </div>
        <div className='play-sidebar__progress'>
          <input
            type='range'
            min='0'
            max='100'
            value={progress}
            onChange={handleOnProgressChange}
            className='play-sidebar__progress-bar'
            style={progressStyle} 
          />
        </div>
      </div>
    </div>
  );
};

export default PlaySidebar;
