import React from 'react';
import { CameraMode } from "../code/CanvasModeCode";

interface ViewPointButtonProps {
  activeButton: CameraMode;
  onCameraModeChange: (mode: CameraMode) => void;
}

const ViewPointButton: React.FC<ViewPointButtonProps> = ({ activeButton, onCameraModeChange }) => {

  
  return (
    <div className='left-button'>
      <button
        className={activeButton === CameraMode.TOP ? "active" : ""}
        onClick={() => onCameraModeChange(CameraMode.TOP)}
      >
        Top
      </button>
      <button
        className={activeButton === CameraMode.LEFT ? "active" : ""}
        onClick={() => onCameraModeChange(CameraMode.LEFT)}
      >
        Left
      </button>
      <button
        className={activeButton === CameraMode.RIGHT ? "active" : ""}
        onClick={() => onCameraModeChange(CameraMode.RIGHT)}
      >
        Right
      </button>
      <button
        className={activeButton === CameraMode.FRONT ? "active" : ""}
        onClick={() => onCameraModeChange(CameraMode.FRONT)}
      >
        Front
      </button>
      <button
        className={activeButton === CameraMode.BACK ? "active" : ""}
        onClick={() => onCameraModeChange(CameraMode.BACK)}
      >
        Back
      </button>
      <button
        className={activeButton === CameraMode.THREE_DIMENSION ? "active" : ""}
        onClick={() => onCameraModeChange(CameraMode.THREE_DIMENSION)}
      >
        3D
      </button>
    </div>
  );
};

export default ViewPointButton;
