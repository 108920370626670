import React from 'react';
// 소켓 작업
// import useSettingStore from '../../atoms/settingsStore';
// import useSocketStore from '../../atoms/socketStore';
// import { hopperSettingDataToHopperSettingReqData } from '../../converter/SocketConverter';
// import { SocketCommand } from '../../command/SocketCommand';

const HopperSettings: React.FC = () => {
  const hopperData = [
    {
      title: 'Hopper Specification',
      data : [
        { label : '폭(m)', value: 'Width Hopper', inputValue:''},
        { label : '길이(m)', value: 'Length Hopper', inputValue:''},
      ]
    },
    {
      title: 'Center Position of Hopper 1',
      data : [
        { label : 'X좌표(m)', value: 'X_Hopper1', inputValue: '' },
        { label : 'Y좌표(m)', value: 'Y_Hopper1', inputValue: '' },
        { label : 'Z좌표(m)', value: 'Z_Hopper1', inputValue: '' },
      ]
    },
    {
      title: 'X Position og Hopper',
      data: [
        { label: 'Hopper 2', value: 'X_Hopper2', inputValue: '' },
      ]
    },
    {
      title: 'ETC',
      data : [
        { label: '선두께', value: 'X_Hopper2', inputValue: 1},
        { label: '색갈', value: '', inputValue: ''},
      ]
    }
  ]
  return (
    <div className='settings'>
      <table className='table'>
        <tr>
          <td>호퍼 갯수</td>
          <td>NO HOPPER</td>
          <td>
            <input type="number" defaultValue={2} />
          </td>
        </tr>

        {hopperData.map((hoper, index) => (
          <React.Fragment key={index}>
            <tr>
              <td colSpan={3} className='table-header'>
                {hoper.title}
              </td>
            </tr>
            {hoper.data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>{row.label}</td>
                <td>{row.label}</td>
                <td>
                  <input type="number" defaultValue={row.inputValue} />
                </td>
              </tr>
            ))}
          </React.Fragment>
        ))}

      </table>
    </div>
  );

  // 소켓 작업
  // const hopperSetting = useSettingStore((state) => state.hopperSetting);
  // const hopperData = hopperSetting;
  // const send = useSocketStore((state) => state.send);
  // const handleSaveButtonClick = () => {
  //   const hopperSettingReqData = hopperSettingDataToHopperSettingReqData(hopperSetting);
  //   send(SocketCommand.HOPPER_SETTING, hopperSettingReqData, (response) => console.log(response));
  // }


  // return (
  //   <div className='settings'>
  //     <table className='table'>
  //       <tbody>
  //       <tr>
  //         <td>호퍼 갯수</td>
  //         <td>NO HOPPER</td>
  //         <td>
  //           <input type="number" defaultValue={hopperData.no_Hopper} />
  //         </td>
  //       </tr>
  //       <tr>
  //         <td colSpan={3} className='table-header'>
  //           Hopper Specification
  //         </td>
  //       </tr>
  //       <tr>
  //         <td>폭(m)</td>
  //         <td>폭(m)</td>
  //         <td>
  //           <input type="number" defaultValue={hopperData.width_Hopper} />
  //         </td>
  //       </tr>
  //       <tr>
  //         <td>길이(m)</td>
  //         <td>길이(m)</td>
  //         <td>
  //           <input type="number" defaultValue={hopperData.length_Hopper} />
  //         </td>
  //       </tr>
  //       {hopperData.centerPositionOfHopper.map((hopper, index) => (
  //         <React.Fragment key={index}>
  //           <tr>
  //             <td colSpan={3} className='table-header'>
  //               Center Position of Hopper {index + 1}
  //             </td>
  //           </tr>
  //           <tr>
  //             <td>X좌표(m)</td>
  //             <td>X좌표(m)</td>
  //             <td>
  //               <input type="number" defaultValue={hopper.X_Hopper} />
  //             </td>
  //           </tr>
  //           <tr>
  //             <td>Y좌표(m)</td>
  //             <td>Y좌표(m)</td>
  //             <td>
  //               <input type="number" defaultValue={hopper.Y_Hopper} />
  //             </td>
  //           </tr>
  //           <tr>
  //             <td>Z좌표(m)</td>
  //             <td>Z좌표(m)</td>
  //             <td>
  //               <input type="number" defaultValue={hopper.Z_Hopper} />
  //             </td>
  //           </tr>

  //         </React.Fragment>
  //       ))}
  //       </tbody>
  //     </table>
  //   </div>
  // );
};

export default HopperSettings;
