import React from 'react'
// 소켓 작업
// import useSocketStore from '../../atoms/socketStore';
// import useSettingStore from '../../atoms/settingsStore';
// import { dataSettingDataToDataSettingReqData } from '../../converter/SocketConverter';
// import { SocketCommand } from '../../command/SocketCommand';

const DataSettings = () => {
  // 소켓 작업
  // const send = useSocketStore(state => state.send);
  // const dataSetting = useSettingStore(state => state.dataSetting);
  // const handleSaveButtonClick = () => {
  //   const dataSettingReqData = dataSettingDataToDataSettingReqData(dataSetting);
  //   send(SocketCommand.DATA_SETTING, dataSettingReqData, (response) => console.log(response));
  // }

  return (
    <div>DataSettings</div>
  )
}

export default DataSettings