import React, { useState } from 'react';
import LiDARSettings from './settings/LiDARSettings';
import HopperSettings from './settings/HopperSettings';
import StorageSettings from './settings/StorageSettings';
import DumpingDoorSettings from './settings/DumpingDoorSettings';
import GridSettings from './settings/GridSettings';
import ScreenSettings from './settings/ScreenSettings';
import DataSettings from './settings/DataSettings';

const menuItems = [
  { name: 'LiDAR 설정', component: <LiDARSettings /> },
  { name: '호퍼 설정', component: <HopperSettings /> },
  { name: '저장조 설정', component: <StorageSettings /> },
  { name: '투입문 설정', component: <DumpingDoorSettings /> },
  { name: 'GRID 설정', component: <GridSettings /> },
  { name: '화면 설정', component: <ScreenSettings /> },
  { name: 'DATA 설정', component: <DataSettings /> },
];

const LiveSidebar: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleMenuClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index); 
  };

  return (
    <div className="sidebar">
      <ul>
        {menuItems.map((item, index) => (
          <li
            key={index}
            className={`menuItem ${activeIndex === index ? 'active' : ''}`}
          >
         <div className='title-container'>
              <div
                className="menuTitle"
                onClick={() => handleMenuClick(index)}
              >
                {item.name}
              </div>
              <div className="toggleIcon" onClick={() => handleMenuClick(index)}>
                {activeIndex === index ? '▲' : '▼'} 
              </div>
         </div>
            <div className="menuContent">
              {activeIndex === index && item.component}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LiveSidebar;
