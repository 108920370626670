import { useCallback, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { gradientColors, pointCloudConverter } from '../converter/ThreeDimensionConverter';
import { CameraMode, RenderMode } from '../code/CanvasModeCode';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { NURBSSurface } from 'three/examples/jsm/curves/NURBSSurface.js';
import { ParametricGeometry } from 'three/examples/jsm/geometries/ParametricGeometry.js';
import Bar from './Bar';

// 소켓 작업
// import useSocketStore from '../atoms/socketStore';
// import { SocketCommand } from '../command/SocketCommand';
// import { PointListResData } from '../data/network/res/ResponseData';

interface ThreeDimensionVisualProps {
    renderMode: RenderMode;
    cameraMode: CameraMode;
}

const scene = new THREE.Scene();
let aspect = window.innerWidth / window.innerHeight;
const frustumSize = 1000;

// 카메라 설정 (쿼터뷰)
const perspectiveCamera = new THREE.PerspectiveCamera(
    75,                                     // 시야각(FOV)
    aspect, // 종횡비(aspect ratio)
    0.1,                                    // near clipping plane
    5000                                    // far clipping plane
);

const orthoCamera = new THREE.OrthographicCamera(
    -frustumSize * aspect / 2,  // left
    frustumSize * aspect / 2,   // right
    frustumSize / 2,            // top
    -frustumSize / 2,           // bottom
    0.1,                        // near clipping plane
    5000                        // far clipping plane
);

let currentCamera : any;
currentCamera = perspectiveCamera;

const ThreeDimensionVisual = (props: ThreeDimensionVisualProps) => {    
    
    // 소켓 작업
    // const socket = useSocketStore(state => state.socket);

    const mountRef = useRef<HTMLDivElement | null>(null);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    const controls = new OrbitControls(currentCamera, renderer.domElement);   

    // 직육면체 크기 설정
    const containerWidth = 1000;
    const containerHeight = 500;
    const containerDepth = 500;

    controls.target.set(containerWidth / 2, containerHeight / 2, containerDepth / 2);
    controls.update(); // 필수: target 변경 후 업데이트 필요

    // box
    const boxWidthCount = 100;
    const boxHeightCount = 50;
    const boxDepthCount = 50;

    const boxWidthSize = containerWidth / boxWidthCount;
    const boxHeightSize = containerHeight / boxHeightCount;
    const boxDepthSize = containerDepth / boxDepthCount;

    const widthCoordinateIndex = 0;
    const heightCoordinateIndex = 1;
    const depthCoordinateIndex = 2;

    const boxCenterWidthPosition = boxWidthSize / 2;
    const boxCenterHeightPosition = boxHeightSize / 2;
    const boxCenterDepthPosition = boxDepthSize / 2;
    
    let points : any;
    let contourPoints : any;

    useEffect(()=> {
        init();
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        renderInit();
        // 소켓 작업
        // renderInit(contourPoints);
        // eslint-disable-next-line
    },[props.renderMode]);    

    useEffect(() => {
        changeCameraMode();
        console.log(currentCamera);
      
        // eslint-disable-next-line
    },[props.cameraMode]);    

    const init = () => {
        renderGrid();
        renderInit();
        // 초기화할 때 스타일을 설정
        renderer.domElement.style.width = '100%';
        renderer.domElement.style.height = '100%'
    }

    const renderInit = async() => {
        await reqData();
        points = contourPoints.flat();
        renderObject();
    }
 

    //소켓 작업
    // const init = () => {
    //     renderGrid();
    //     socketInit();
    //     // 초기화할 때 스타일을 설정
    //     renderer.domElement.style.width = '100%';
    //     renderer.domElement.style.height = '100%'
    // }
    // const socketInit = () => {
    //     if (socket?.hasListeners(SocketCommand.POINT_LIST)) {
    //         socket.off(SocketCommand.POINT_LIST);
    //     }
    //     socket?.on(SocketCommand.POINT_LIST, (data: PointListResData) => {
    //         contourPoints = data.pointList;
    //         renderInit(contourPoints);
    //     });
    // }
    // const renderInit = (contourPoints: any) => {
    //     // await reqData();
    //     try {
    //         points = contourPoints.flat();
    //         renderObject();
    //     } catch (error) {
            
    //     }
    // }

    const renderObject = () => {
        clearScene();
        switch (props.renderMode) {
            case RenderMode.CONTOUR_LINE:
                renderContourLine(contourPoints);
                break;
            case RenderMode.BOX:
                renderBox(points, contourPoints);
                break;            
            case RenderMode.POINT_CLOUD:
            default:
                renderPointCloud(points);
                break;
        }
    };    

    const reqData = async() => {
        const response = await fetch('./flat_points.json'); // 데이터셋의 경로 
        contourPoints = await response.json();
        return contourPoints;
    }

    const renderGrid = () => {
        const mount = mountRef.current;
        if (!mount) return;       
    
        // Create the renderer
        renderer.setSize(window.innerWidth, window.innerHeight);


        mount.appendChild(renderer.domElement);       
        
        currentCamera.position.set(1.3 * (-(containerWidth / 2)), (1.6 * (containerHeight / 2)), containerDepth * 1.3); // 카메라를 사선으로 배치
        currentCamera.lookAt(containerWidth / 2, containerHeight / 2, containerDepth / 2); // 카메라의 시점을 원점으로 설정      

        // 직육면체의 면에 그리드를 추가하는 함수
        const createFaceGrid = (width: number, height: number, divisions: number, color: number): THREE.LineSegments => {
            // 그리드를 위한 라인 생성
            const gridGeometry = new THREE.BufferGeometry();
            const vertices: number[] = [];

            // 스텝 크기 계산
            const stepW = width / divisions;
            const stepH = height / divisions;

            // 수직선 생성
            for (let i = 0; i <= divisions; i++) {
                const x = i * stepW;
                vertices.push(x, 0, 0, x, height, 0); // 수직선: x 변화, y 고정
            }

            // 수평선 생성
            for (let i = 0; i <= divisions; i++) {
                const y = i * stepH;
                vertices.push(0, y, 0, width, y, 0); // 수평선: y 변화, x 고정
            }

            gridGeometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
            const gridMaterial = new THREE.LineBasicMaterial({ color });
            return new THREE.LineSegments(gridGeometry, gridMaterial);
        };

        // 그리드를 위한 그룹 생성
        const gridGroup = new THREE.Group();

        // 직육면체의 각 면에 그리드 추가
        const addFaceGrids = (width: number, height: number, depth: number, divisions: number) => {
            // 상단 면 (XY 평면)
            const gridXYTop = createFaceGrid(width, depth, divisions, 0x444444);
            gridXYTop.rotation.x = Math.PI / 2;  // XY 평면 그리드 (Z축 회전)
            gridXYTop.position.set(0, height, 0); // 상단 Y축 양수 방향
            // gridGroup.add(gridXYTop);

            // 하단 면 (XY 평면)
            const gridXYBottom = createFaceGrid(width, depth, divisions, 0xffffff);
            gridXYBottom.rotation.x = Math.PI / 2;  // XY 평면 그리드 (Z축 회전)
            gridXYBottom.position.set(0, 0, 0); // 원점에서 시작
            gridGroup.add(gridXYBottom);

            // 앞면 (XZ 평면)
            const gridXZFront = createFaceGrid(width, height, divisions, 0xffffff);
            gridXZFront.position.set(0, 0, depth); // Z축 양수 방향으로 이동
            // gridGroup.add(gridXZFront);

            // 뒷면 (XZ 평면)
            const gridXZBack = createFaceGrid(width, height, divisions, 0xffffff);
            gridXZBack.position.set(0, 0, 0); // Z축 원점에 배치
            gridGroup.add(gridXZBack);

            // 왼쪽 면 (YZ 평면)
            const gridYZLeft = createFaceGrid(depth, height, divisions, 0xffffff);
            gridYZLeft.rotation.y = -Math.PI / 2; // YZ 평면 그리드 (X축 회전)
            gridYZLeft.position.set(0, 0, 0); // 원점에서 시작
            // gridGroup.add(gridYZLeft);

            // 오른쪽 면 (YZ 평면)
            const gridYZRight = createFaceGrid(depth, height, divisions, 0xffffff);
            gridYZRight.rotation.y = -Math.PI / 2; // YZ 평면 그리드 (X축 회전)
            gridYZRight.position.set(width, 0, 0); // X축 양수 방향
            gridGroup.add(gridYZRight);
        };

        // 그리드를 장면에 추가
        addFaceGrids(containerWidth, containerHeight, containerDepth, 5);
        scene.add(gridGroup);

        // P1 -> P2 (z축 방향)
        addGridLabels(
            new THREE.Vector3(0, 0, 0), // P1 (원점)
            new THREE.Vector3(0, 0, containerDepth), // P2 (Z축 양수 방향)
            60,
            scene
        );

        // P1 -> P3 (x축 방향)
        addGridLabels(
            new THREE.Vector3(0, containerHeight, 0), // P1 (Y축 양수 방향)
            new THREE.Vector3(containerWidth, containerHeight, 0), // P2 (X축 양수 방향)
            100,
            scene
        );

        // P1 -> P5 (y축 방향)
        addGridLabels(
            new THREE.Vector3(0, 0, 0), // P1 (원점)
            new THREE.Vector3(0, containerHeight, 0), // P3 (Y축 양수 방향)
            60,
            scene
        );

        // 애니메이션 루프
        const animate = () => {
            requestAnimationFrame(animate);
            renderer.render(scene, currentCamera);
        };

        animate();

        // Handle window resizing
        const onResize = () => {
            currentCamera.aspect = aspect;
            currentCamera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
            renderer.domElement.style.width = '100%';
            renderer.domElement.style.height = '100%';

            renderer.render(scene, currentCamera);
        };

        window.addEventListener('resize', onResize);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('resize', onResize);
            mount.removeChild(renderer.domElement);
        };
    }

    const renderPointCloud = useCallback(async(points: any) => {
        const pointCloud = pointCloudConverter(points);
        scene.add(pointCloud);
        // eslint-disable-next-line
    },[]);

    const renderContourLine = useCallback(async(contourPoints: any) => {
        // ambient light
        scene.add( new THREE.AmbientLight( 0x222222 ) );
        
        // directional light
        const light = new THREE.DirectionalLight( 0xffffff, 1 );
        light.position.set( 80, 80, 80 );
        scene.add( light );

        let nurbsControlPoints: any[][] = [];
        const nurbsKnotsU = [];
        const nurbsKnotsV = [];
        const nurbsDegreeU = 3;
        const nurbsDegreeV = 2;
        const defaultW = 1.0;

        for ( let i = 0; i <= nurbsDegreeU; i ++ ) {
            nurbsKnotsU.push( 0 );
        }
        for ( let i = 0; i <= nurbsDegreeV; i ++ ) {
            nurbsKnotsV.push( 0 );        
        }
        const divisionsU = contourPoints.length; // U 방향 분할 수
        const divisionsV = contourPoints[0].length; // V 방향 분할 수
        
        // nurbsControlPoints 2차원 배열 생성
        for (let i = 0; i < divisionsU; i++) {
            const innerArray = [];
            for (let j = 0; j < divisionsV; j++) {
                const [x, z, y] = contourPoints[i][j]; // [x, y, z] 배열에서 값을 추출
                innerArray.push(new THREE.Vector4(x, y, z, defaultW)); // Vector4로 변환
            }
            nurbsControlPoints.push(innerArray); // 2차원 배열에 추가
        }

        for(let i = 0; i < divisionsU; i++) {
            const knotU = ( i + 1 ) / ( divisionsU - nurbsDegreeU );
            nurbsKnotsU.push( THREE.MathUtils.clamp( knotU, 0, 1 ) );
        };

        for(let i = 0; i < divisionsV; i++) {
            const knotV = (i + 1 ) / ( divisionsV - nurbsDegreeV );
            nurbsKnotsV.push( THREE.MathUtils.clamp( knotV, 0, 1 ) );
        };       

        const nurbsSurface = new NURBSSurface( nurbsDegreeU, nurbsDegreeV, nurbsKnotsU, nurbsKnotsV, nurbsControlPoints );

        function getSurfacePoint( u:any, v:any, target:any ) {
            return nurbsSurface.getPoint( u, v, target );
        }
        
        const geometry = new ParametricGeometry( getSurfacePoint, 20, 20 );
         // 높이에 따른 색상 그라데이션 셰이더 적용 (Fragment Shader)
        const material = new THREE.ShaderMaterial({
            uniforms: {
            minHeight: { value: 0 },
            maxHeight: { value: containerDepth },
            gradientColors: { value: gradientColors }
            },
            vertexShader: `
            varying vec3 vPosition;
            void main() {
                vPosition = position;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
            `,
            fragmentShader: `
            varying vec3 vPosition;
            uniform float minHeight;
            uniform float maxHeight;
            uniform vec3 gradientColors[10];

            void main() {
                float heightFraction = (vPosition.y - minHeight) / (maxHeight - minHeight);
                // 색상 배열에서 두 색상 인덱스와 보간 비율 계산
                float numColors = float(10); // 색상 배열의 길이
                float index = heightFraction * (numColors - 1.0);
                int lowerIndex = int(floor(index));
                int upperIndex = int(ceil(index));
                float blend = index - float(lowerIndex);
                
                vec3 color = mix(gradientColors[lowerIndex], gradientColors[upperIndex], blend);
                gl_FragColor = vec4(color, 1.0);
            }
            `,
            side: THREE.DoubleSide,
            wireframe: false
        });
        const object = new THREE.Mesh( geometry, material );
        scene.add( object );

        // 격자 모양의 NURBS 선을 생성하는 함수
        function createThickNURBSGridLines(nurbsSurface : any, divisionsU :any, divisionsV : any) {
            const tubeMeshes = [];

            // 경로를 생성하는 함수
            function createPathFromPoints(points : any) {
                return new THREE.CatmullRomCurve3(points);
            }

            // 선의 두께와 분할 수 설정
            const tubeRadius = 1;
            const tubularSegments = 32;

            // U 방향 (가로선) 생성
            for (let v = 0; v <= divisionsV; v+=2) {
                const points = [];
                const vValue = v / divisionsV; // V 값 고정
                for (let u = 0; u <= divisionsU; u++) {
                    const uValue = u / divisionsU; // U 값을 0~1 사이로 분할
                    const point = new THREE.Vector3(); // 점을 담을 벡터 생성
                    nurbsSurface.getPoint(uValue, vValue, point); // NURBS 곡면에서 점 샘플링
                    points.push(point); // 점 추가
                }

                const path = createPathFromPoints(points);
                const tubeGeometry = new THREE.TubeGeometry(path, tubularSegments, tubeRadius, 8, false);
                const tubeMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, side: THREE.DoubleSide });
                const tubeMesh = new THREE.Mesh(tubeGeometry, tubeMaterial);
                tubeMeshes.push(tubeMesh); // 선을 저장
            }

            // V 방향 (세로선) 생성
            for (let u = 0; u <= divisionsU; u+=2) {
                const points = [];
                const uValue = u / divisionsU; // U 값 고정
                for (let v = 0; v <= divisionsV; v++) {
                    const vValue = v / divisionsV; // V 값을 0~1 사이로 분할
                    const point = new THREE.Vector3(); // 점을 담을 벡터 생성
                    nurbsSurface.getPoint(uValue, vValue, point); // NURBS 곡면에서 점 샘플링
                    points.push(point); // 점 추가
                }

                const path = createPathFromPoints(points);
                const tubeGeometry = new THREE.TubeGeometry(path, tubularSegments, tubeRadius, 8, false);
                const tubeMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, side: THREE.DoubleSide });
                const tubeMesh = new THREE.Mesh(tubeGeometry, tubeMaterial);
                tubeMeshes.push(tubeMesh); // 선을 저장
            }

            return tubeMeshes; // 생성된 모든 선 반환
        }

        // NURBS 곡면에서 격자 모양의 두꺼운 선 생성
        const thickNURBSLines = createThickNURBSGridLines(nurbsSurface, divisionsU, divisionsV);

        // 씬에 모든 선 추가
        thickNURBSLines.forEach(line => scene.add(line));
        // eslint-disable-next-line
    },[]);

    const renderBox = useCallback(async(points: any, contourPoints :any) => {
        const boxes: THREE.Object3D[] = [];
        const geometry = new THREE.BoxGeometry(boxWidthSize, boxHeightSize, boxDepthSize);
        for (let i = 0; i < boxWidthCount; i++) {
            for (let j = 0; j < boxHeightCount; j++) {
                // points 배열에서 현재 index의 x, y 값에 포함된 points만 추출  
                const boxPoints = points.filter((point: any) => {
                    return point[widthCoordinateIndex] >= (i * boxWidthSize) &&
                        point[widthCoordinateIndex] < ((i + 1) * boxWidthSize) &&
                        point[heightCoordinateIndex] >= (j * boxHeightSize) &&
                        point[heightCoordinateIndex] < ((j + 1) * boxHeightSize);
                });

                if(boxPoints.length === 0) continue;

                const maxDepthPoint = boxPoints.reduce((prev: any, current: any) => {
                    return prev[depthCoordinateIndex] > current[depthCoordinateIndex] ? prev : current;
                });

                const normalizedDepth = maxDepthPoint[depthCoordinateIndex] / containerDepth;

                // normalizedDepth를 이용하여 gradientColors 배열에서 색상을 가져옴
                const color = gradientColors[Math.floor(normalizedDepth * gradientColors.length)];
          
                const box = new THREE.Mesh(
                    geometry,
                    new THREE.MeshBasicMaterial({color: color})
                );
                box.position.set(i * boxWidthSize + boxCenterWidthPosition , maxDepthPoint[depthCoordinateIndex] + boxCenterDepthPosition , j * boxHeightSize + boxCenterHeightPosition);
                boxes.push(box);

                const edges = new THREE.EdgesGeometry(geometry);
                const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: 0x000000 }));
                line.position.copy(box.position);
                boxes.push(line);
            }
        }

        const outerPoints: any = [];

        // 첫 번째 행
        outerPoints.push(...contourPoints[0]);

        // 마지막 행 (첫 번째와 중복되지 않도록 조건)
        if (points.length > 1) {
        outerPoints.push(...contourPoints[contourPoints.length - 1]);
        }

        // 첫 번째 열과 마지막 열
        for (let i = 1; i < contourPoints.length - 1; i++) {
        outerPoints.push(contourPoints[i][0]); // 첫 번째 열
            if (contourPoints[i].length > 1) {
                outerPoints.push(contourPoints[i][contourPoints[i].length - 1]); // 마지막 열
            }
        }

        outerPoints.forEach((point : any) => {
            // 해당 점의 깊이에서 y=0까지 박스를 채우기
            const depth = point[depthCoordinateIndex];
            const numBoxes = Math.ceil(depth / boxHeightSize); // 필요한 박스의 개수 계산

            for (let i = 0; i < numBoxes; i++) {
                const yPos = depth - (i * boxHeightSize) - boxHeightSize / 2; // 각 박스의 y 위치 설정

                // 고정된 박스 크기
                const geometry = new THREE.BoxGeometry(boxWidthSize, boxHeightSize, boxHeightSize);
                const outerNormalizedDepth = yPos / containerDepth;
    
                // normalizedDepth를 이용하여 gradientColors 배열에서 색상을 가져옴
                const color = gradientColors[Math.floor(outerNormalizedDepth * gradientColors.length)];
                const box = new THREE.Mesh(
                    geometry,
                    new THREE.MeshBasicMaterial({ color: color })
                );

                // 박스의 위치를 설정
                box.position.set(
                    point[widthCoordinateIndex], // x 좌표
                    yPos,                        // y 좌표 (박스가 y=0에서 깊이까지 쌓임)
                    point[heightCoordinateIndex] // z 좌표
                );

                // 박스를 씬에 추가
                boxes.push(box);
                // 박스의 가장자리에 선을 추가
                const edges = new THREE.EdgesGeometry(geometry);
                const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: 0x000000 }));                
                // 선의 위치를 박스와 동일하게 설정
                line.position.copy(box.position);
                boxes.push(line);
            }          
        });
        
        boxes.forEach(box => scene.add(box));
        // eslint-disable-next-line
    },[]);

    const clearScene = () => {
        for (let i = scene.children.length - 1; i >= 0; i--) {
            if (scene.children[i].type === "Mesh" || scene.children[i].type === "Points" || scene.children[i].type === "LineSegments") {
                scene.remove(scene.children[i]);
            };            
        }
    };


    // 그리드 간격마다 텍스트 스프라이트를 추가하는 함수
    const addGridLabels = (
        start: THREE.Vector3,
        end: THREE.Vector3,
        step: number,
        scene: THREE.Scene
    ) => {
        const direction = new THREE.Vector3().subVectors(end, start).normalize();
        const distance = start.distanceTo(end);

        for (let i = 0; i <= distance; i += step) {
        const position = new THREE.Vector3().addVectors(
            start,
            direction.clone().multiplyScalar(i)
        );
        const label = (10 * (i / step)).toString(); // 숫자 라벨
        const sprite = createTextSprite(label, position);
        if (sprite) scene.add(sprite);
        }
    };

     // 텍스트 스프라이트를 생성하는 함수
     const createTextSprite = (text: string, position: THREE.Vector3) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) return null;

        const fontSize = 100;
        context.font = `${fontSize}px Arial`;
        context.fillStyle = 'white';
        context.fillText(text, 0, fontSize);

        const texture = new THREE.CanvasTexture(canvas);
        const material = new THREE.SpriteMaterial({ map: texture });
        const sprite = new THREE.Sprite(material);
        sprite.scale.set(50, 25, 1); // 텍스트 크기 조절
        sprite.position.copy(position);

        return sprite;
    };

    const changeCameraMode = () => {

        switch (props.cameraMode) {
            case CameraMode.TOP:
                currentCamera = orthoCamera;
                // 카메라를 오브젝트의 상단에 배치
                currentCamera.position.set(containerWidth / 2, containerHeight *2.5, containerDepth / 2); // 위쪽에서 내려다보는 위치
                // 카메라가 오브젝트의 중심을 바라보도록 설정
                currentCamera.lookAt(containerWidth / 2, containerHeight / 2, containerDepth / 2);
                break;
            
            case CameraMode.LEFT:
                currentCamera = orthoCamera;
                // 카메라를 오브젝트의 왼쪽에 배치
                currentCamera.position.set(-containerWidth * 0.5, containerHeight / 2, containerDepth / 2); // 왼쪽에서 보는 위치
                // 카메라가 오브젝트의 중심을 바라보도록 설정
                currentCamera.lookAt(containerWidth / 2, containerHeight / 2, containerDepth / 2);
                break;
            
            case CameraMode.RIGHT:
                currentCamera = orthoCamera;
                // 카메라를 오브젝트의 오른쪽에 배치
                currentCamera.position.set(containerWidth * 1.5, containerHeight / 2, containerDepth / 2); // 오른쪽에서 보는 위치
                // 카메라가 오브젝트의 중심을 바라보도록 설정
                currentCamera.lookAt(containerWidth / 2, containerHeight / 2, containerDepth / 2);
                break;
            
            case CameraMode.FRONT:
                currentCamera = orthoCamera;
                // 카메라를 오브젝트의 정면에 배치
                currentCamera.position.set(containerWidth / 2, containerHeight / 2, containerDepth * 2); // 정면에서 보는 위치
                // 카메라가 오브젝트의 중심을 바라보도록 설정
                currentCamera.lookAt(containerWidth / 2, containerHeight / 2, containerDepth / 2);
                break;
            
            case CameraMode.BACK:
                currentCamera = orthoCamera;
                // 카메라를 오브젝트의 뒤쪽에 배치
                currentCamera.position.set(containerWidth / 2, containerHeight / 2, -containerDepth); // 뒤쪽에서 보는 위치
                // 카메라가 오브젝트의 중심을 바라보도록 설정
                currentCamera.lookAt(containerWidth / 2, containerHeight / 2, containerDepth / 2);
                break;
            case CameraMode.THREE_DIMENSION:
            default:
                currentCamera = perspectiveCamera;
                currentCamera.position.set((-containerWidth/2), (containerHeight), (2*containerDepth)); // 카메라를 사선으로 배치
                currentCamera.lookAt(containerWidth / 2, containerHeight / 2, containerDepth / 2); // 카메라의 시점을 원점으로 설정   
                break;
        }
        renderer.render(scene, currentCamera);
    }

    return (
        <>           
            <div ref={mountRef}/>
            <Bar/>
        </>
    );    
};

export default ThreeDimensionVisual;