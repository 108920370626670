import * as THREE from 'three';

// 색상 배열
export const gradientColors = [
    new THREE.Color(0x8B00FF), // Violet
    new THREE.Color(0x4B0082), // Indigo
    new THREE.Color(0x0000FF), // Blue
    new THREE.Color(0x00FFFF), // Cyan
    new THREE.Color(0x00FF00), // Green
    new THREE.Color(0xADFF2F), // Lime
    new THREE.Color(0xFFFF00), // Yellow
    new THREE.Color(0xFF7F00), // Orange
    new THREE.Color(0xFF4500), // Red-Orange
    new THREE.Color(0xFF0000)  // Red
];

export function pointCloudConverter(data : any) { 
    const geometry = new THREE.BufferGeometry();
    const vertices = new Float32Array(data.flat());
    for (let i = 0; i < vertices.length; i += 3) {
        // vertices[i] = x, vertices[i + 1] = y, vertices[i + 2] = z
        // y와 z 값을 교환
        const temp = vertices[i + 1];  // y 값을 임시로 저장
        vertices[i + 1] = vertices[i + 2];  // z 값을 y에 할당
        vertices[i + 2] = temp;  // 임시로 저장한 y 값을 z에 할당
    }
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

    // 색상 배열 생성
    const colors = new Float32Array(vertices.length);

    const diff = 500;
    // 색상을 y 값에 따라 부드럽게 변화
    for (let i = 0; i < vertices.length; i += 3) {
        const z = vertices[i + 1]; // y 값 가져오기
        const normalizedZ = z / diff; // 0 ~ 1 사이로 정규화

        // 색상 배열 내의 인덱스 계산
        const colorIndex = normalizedZ * (gradientColors.length - 1);
        const lowerIndex = Math.floor(colorIndex);
        const upperIndex = Math.min(lowerIndex + 1, gradientColors.length - 1);

        // 색상 보간
        const lowerColor = gradientColors[lowerIndex];
        const upperColor = gradientColors[upperIndex];
        const fraction = colorIndex - lowerIndex;

        // 최종 색상 계산
        const interpolatedColor = lowerColor.clone().lerp(upperColor, fraction);

        // colors 배열에 RGB 값 넣기
        colors[i] = interpolatedColor.r;
        colors[i + 1] = interpolatedColor.g;
        colors[i + 2] = interpolatedColor.b;
    }

    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
    const material = new THREE.PointsMaterial({ size: 1, vertexColors: true });
    return new THREE.Points(geometry, material);
}