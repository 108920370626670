import React from 'react'
// 소켓 작업
// import useSocketStore from '../../atoms/socketStore';
// import useSettingStore from '../../atoms/settingsStore';
// import { storageSettingDataToStorageSettingReqData } from '../../converter/SocketConverter';
// import { SocketCommand } from '../../command/SocketCommand';

const StorageSettings = () => {
  // 소켓 작업
  // const send = useSocketStore(state => state.send);
  // const storageSetting = useSettingStore(state => state.storageSetting);
  // const handleSaveButtonClick = () => {
  //   const storageSettingReqData = storageSettingDataToStorageSettingReqData(storageSetting);
  //   send(SocketCommand.STORAGE_SETTING, storageSettingReqData, (response) => console.log(response));
  // }
  
  return (
    <div>StorageSettings</div>
  )
}

export default StorageSettings