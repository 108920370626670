import React from 'react'
// 소켓 작업
// import useSocketStore from '../../atoms/socketStore';
// import useSettingStore from '../../atoms/settingsStore';
// import { screenSettingDataToScreenSettingReqData } from '../../converter/SocketConverter';
// import { SocketCommand } from '../../command/SocketCommand';

const ScreenSettings = () => {
  // 소켓 작업
  // const send = useSocketStore(state => state.send);
  // const screenSetting = useSettingStore(state => state.screenSetting);
  // const handleSaveButtonClick = () => {
  //   const screenSettingReqData = screenSettingDataToScreenSettingReqData(screenSetting);
  //   send(SocketCommand.SCREEN_SETTING, screenSettingReqData, (response) => console.log(response));
  // }
  return (
    <div>ScreenSettings</div>
  )
}

export default ScreenSettings