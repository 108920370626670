import React, { useState, useCallback } from 'react';
import { CameraMode, RenderMode } from "../code/CanvasModeCode";
import ViewPointButton from "./ViewPointButton";
import ThreeDimensionVisual from "./ThreeDimensionVisual";
import RenderModeButton from "./RenderModeButton";

interface CanvasProps {
  children?: React.ReactNode;
  handleOnCameraModeChange?: (mode: CameraMode) => void;
}

const Canvas: React.FC<CanvasProps> = (props) => {
  const [activeButton, setActiveButton] = useState(CameraMode.THREE_DIMENSION);
  const [renderMode, setRenderMode] = useState(RenderMode.POINT_CLOUD);
  const [cameraMode, setCameraMode] = useState(CameraMode.THREE_DIMENSION);

  // props에서 필요한 값만 분해하여 사용
  const { handleOnCameraModeChange } = props;

  const handleOnCameraModeChangeCallback = useCallback((cameraMode: CameraMode) => {
    setCameraMode(cameraMode);
    setActiveButton(cameraMode);
    handleOnCameraModeChange?.(cameraMode);
  }, [handleOnCameraModeChange]);

  const handleOnRenderModeChange = useCallback((mode: RenderMode) => {
    setRenderMode(mode);
  }, []);

  const renderComponent = (renderMode: RenderMode): JSX.Element | null => {
    switch (renderMode) {
      case RenderMode.BOX:
      case RenderMode.POINT_CLOUD:
      case RenderMode.CONTOUR_LINE:
        return (
          <ThreeDimensionVisual 
            renderMode={renderMode}
            cameraMode={cameraMode}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="canvas-wrapper">
      <div className='button-container'>
        <ViewPointButton
          activeButton={activeButton}
          onCameraModeChange={handleOnCameraModeChangeCallback}
        />
        <RenderModeButton
          renderMode={renderMode}
          onRenderModeChange={handleOnRenderModeChange}
        />
      </div>
      <div className='main-content'>
        {renderComponent(renderMode)}
      </div>
    </div>
  );
};

export default Canvas;
