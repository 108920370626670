// import { useState, useCallback, useEffect } from 'react';
import { useState, useCallback } from 'react';
import Header from './components/Header';
import Canvas from './components/Canvas';
import ThreeDimensionVisual from "./components/ThreeDimensionVisual";
import { CameraMode, RenderMode } from "./code/CanvasModeCode";
import StorageTankStatus from './components/StorageTankStatus';
import LiveSidebar from './components/LiveSidebar';
import PlaySidebar from './components/PlaySidebar';
// import useSocketStore from "./atoms/socketStore";
// import useSettingsStore from "./atoms/settingsStore";
// import { SocketCommand } from './command/SocketCommand';
// import { settingsReceivedDataToSettingsData } from './converter/SocketConverter';

function App() {
	const [cameraMode, setCameraMode] = useState(CameraMode.THREE_DIMENSION);
	const [activeTab, setActiveTab] = useState('Live');
	// 소켓 작업
	// const [socket, addListener] = useSocketStore(state => [state.socket, state.addListener]);
	// const setSettings = useSettingsStore(state =>  state.setSettings);
	// useEffect(() => {
	// 	init();
	// }, []);

	// const init = () => {
	// 	addListener(SocketCommand.SETTINGS, (response) => {
	// 		console.log('received settings');
	// 		const settingData = settingsReceivedDataToSettingsData(response.settings);
	// 		setSettings(settingData);
	// 	});
	// }

	const handleOnCameraModeChange = useCallback((cameraMode: CameraMode) => {
		setCameraMode(cameraMode);
	}, []);

	const handleModeTabOnClick = (mode: string) => {
			setActiveTab(mode);  
	};
	
	const renderComponent = (renderMode: RenderMode): JSX.Element | null => {
		switch (renderMode) {
			case RenderMode.BOX:
			case RenderMode.POINT_CLOUD:
			case RenderMode.CONTOUR_LINE:
				return (
					<Canvas handleOnCameraModeChange={handleOnCameraModeChange}>
						<ThreeDimensionVisual 
							renderMode={renderMode}
							cameraMode={cameraMode}
						/>
					</Canvas>
				);
			default:
				return null;
		}
	};


	return (
		<div className="App">
			<Header handleModeTabOnClick={handleModeTabOnClick} activeTab={activeTab} /> 
			<div className="main-container">
				{activeTab === 'Live' ? <LiveSidebar /> : <PlaySidebar />}
					{renderComponent(RenderMode.POINT_CLOUD)}
				<StorageTankStatus />
			</div>
		</div>
	);
}

export default App;
