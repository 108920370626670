import React from 'react';
import { RenderMode } from "../code/CanvasModeCode";
import { TbPoint } from "react-icons/tb";
import { FaCube } from 'react-icons/fa';
import { GiPathDistance } from 'react-icons/gi';

interface RenderModeButtonProps {
  renderMode: RenderMode;
  onRenderModeChange: (mode: RenderMode) => void;
}

const RenderModeButton: React.FC<RenderModeButtonProps> = ({ renderMode, onRenderModeChange }) => {
  return (
    <div className='right-button'>
      <button
        className={`sidebar-item ${renderMode === RenderMode.POINT_CLOUD ? "active" : ""}`} 
        onClick={() => onRenderModeChange(RenderMode.POINT_CLOUD)}
      >
        <TbPoint className="sicon" />
      </button>
      <button 
        className={`sidebar-item ${renderMode === RenderMode.BOX ? "active" : ""}`} 
        onClick={() => onRenderModeChange(RenderMode.BOX)}
      >
        <FaCube className="sicon" />
      </button>
      <button 
        className={`sidebar-item ${renderMode === RenderMode.CONTOUR_LINE ? "active" : ""}`} 
        onClick={() => onRenderModeChange(RenderMode.CONTOUR_LINE)}
      >
        <GiPathDistance className="sicon" />
      </button>
    </div>
  );
};

export default RenderModeButton;
