import React from 'react';


const getCurrentDateTime = () => {
  const now = new Date();
  const date = now.toLocaleDateString(); 
  const time = now.toLocaleTimeString(); 
  return { date, time };
};

const StorageTankStatus = () => {

  const { date, time } = getCurrentDateTime();

  return (
    <div className="storage-tank-status">
      <h2>저장조 현황</h2>
      <div>
        <p><strong>날짜:</strong> {date}</p>
        <p><strong>시간:</strong> {time}</p>
        <p><strong>저장조 용량:</strong> 10,000 m³</p>
      </div>

      <div className="estimation">
        <p><strong>추정 저장량:</strong></p>
        <div className="input-group">
          <input type="text" placeholder="추정 저장량 1" />
          <span>m³</span>
        </div>
        <div className="input-group">
          <input type="text" placeholder="추정 저장량 2" />
          <span>m³</span>
        </div>
        <div className="input-group">
          <input type="text" placeholder="추정 저장량 3" />
          <span>m³</span>
        </div>
      </div>
    </div>
  );
};

export default StorageTankStatus;
